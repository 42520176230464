import React from 'react'
import Layout from '../components/Layout'
import imgRecepcja from '../images/recepcja.webp'
import imgRoom from '../images/wlascicielki.webp'
import Fade from 'react-reveal/Fade'
import {Seo} from '../components/Seo'

const About = () => {
  return (
    <Layout>
      <Seo title='O Nas | Modelowanie Sylwetki' />

      <div className='page-container'>
        <div className='page multi'>
          <div className='content-1'>
            <h1>O Nas</h1>
            <div className='section'>
              <div className='image-container'>
                <img
                  src={imgRecepcja}
                  width='650px'
                  height='433px'
                  alt='włascicielki'
                ></img>
              </div>
              <div className='text'>
                <div className='logo-background-wrap'>
                  <p>
                    „Modelowanie Sylwetki” to magiczne miejsce stworzone z pasji przez
                    dwie wyjątkowe kobiety. Myślą przewodnią naszego gabinetu było
                    utworzenie miejsca, gdzie w kameralnej i przytulnej atmosferze można
                    zadbać o swoje ciało. Jest to wyjątkowe miejsce dla osób, które
                    niezależnie od swojego wieku chcą zadbać o siebie i o swoje ciało. W
                    naszych gabinetach pozbędziesz się zbędnych kilogramów, wymodelujesz
                    sylwetkę, poprawisz kondycję fizyczną oraz pozbędziesz się
                    niechcianego owłosienia.
                  </p>
                </div>
              </div>
            </div>
            <hr className='ep' />
            <div className='section reverse'>
              <div className='text'>
                <p>
                  Dzięki wiedzy i doświadczeniu naszego zespołu każda wizyta w gabinecie
                  Modelowania Sylwetki jest przyjemną i motywującą odskocznia od
                  codzienności . Miejsce o niepowtarzalnym klimacie i trafioną odpowiedzią
                  na potrzeby ciała w każdym wieku.
                  <br />
                  <br />
                  Jesteśmy dla Ciebie po to, aby pomóc spełniać marzenia o pięknym, ale
                  przede wszystkim zdrowym ciele. W naszym gabinecie znajdują się
                  najwyższej klasy, innowacyjne i najnowocześniejsze urządzenia pochodzące
                  z polskiej kliniki medycznej i kosmetologicznej co daje 100% pewności,
                  że oferowane przez nas usługi są najlepszym wyborem dla naszych
                  klientów. Urządzenia, na których pracujemy stosowane są w całej Europie.
                  Z nami spełnisz swoje marzenia, przyjdź i przekonaj się.
                </p>
              </div>
              <div className='image-container'>
                <img src={imgRoom} width='650px' height='433px' alt='recepcja'></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
